import React, { useEffect, useState } from "react";
import { getUsers } from "../../api/Helper";
import { formatDate, formatTime } from "../../Utilities/Methords";
import Sidebar from "../../Components/Sidebar";
import Loader from "../../Components/Loader/Loader";

const Users = () => {
  if (localStorage.getItem("user") !== "saurabh_llm") {
    window.location.href = "/pages/publish";
  }

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUsers().then((data) => {
      setLoading(false);
      setUsers(data);
    });
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 p-8 bg-gray-100 min-h-screen w-[90vw]">
        <h2 className="mb-6 text-2xl text-gray-700 border-b-2 border-orange-500 pb-2 w-fit">
          Manage Users
        </h2>
        <div className="w-full overflow-hidden bg-white rounded-lg shadow-lg">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="p-4 text-left bg-[#574EE9] text-white font-bold uppercase tracking-wider">ID</th>
                <th className="p-4 text-left bg-[#574EE9] text-white font-bold uppercase tracking-wider">Date</th>
                <th className="p-4 text-left bg-[#574EE9] text-white font-bold uppercase tracking-wider">Time</th>
                <th className="p-4 text-left bg-[#574EE9] text-white font-bold uppercase tracking-wider">Client ID</th>
                <th className="p-4 text-left bg-[#574EE9] text-white font-bold uppercase tracking-wider">Name</th>
                <th className="p-4 text-left bg-[#574EE9] text-white font-bold uppercase tracking-wider">Email</th>
                <th className="p-4 text-left bg-[#574EE9] text-white font-bold uppercase tracking-wider">Mobile No.</th>
                <th className="p-4 text-left bg-[#574EE9] text-white font-bold uppercase tracking-wider">Status</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan="8" className="text-center">
                    <Loader />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {users.map((user) => (
                  <tr
                    key={user.userId}
                    className="hover:bg-orange-50 transition-colors duration-200 even:bg-gray-50"
                  >
                    <td className="p-4 text-gray-600 border-b border-gray-200">
                      {user.userId}
                    </td>
                    <td className="p-4 text-gray-600 border-b border-gray-200">
                      {formatDate(user.createdAt)}
                    </td>
                    <td className="p-4 text-gray-600 border-b border-gray-200">
                      {formatTime(user.createdAt)}
                    </td>
                    <td className="p-4 text-gray-600 border-b border-gray-200">
                      {user.clientID}
                    </td>
                    <td className="p-4 text-gray-600 border-b border-gray-200">
                      {user.name}
                    </td>
                    <td className="p-4 text-gray-600 border-b border-gray-200">
                      {user.email}
                    </td>
                    <td className="p-4 text-gray-600 border-b border-gray-200">
                      {user.mobileNo}
                    </td>
                    <td className="p-4 text-gray-600 border-b border-gray-200">
                      Active
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Users;