import React, { useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar";
import { XMLParser } from "fast-xml-parser";
import { HOST } from "../../config";
import { Copy, Trash } from "lucide-react";

const SitemapEdit = () => {
  const [sitemapUrls, setSitemapUrls] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [priority, setPriority] = useState("");
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);

  const domain = "https://www.lucknowlions.com/";

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleAddUrl = (e) => {
    e.preventDefault();
    setSitemapUrls([
      ...sitemapUrls,
      {
        loc: domain + url,
        lastmod: new Date().toISOString(),
        priority: priority,
      },
    ]);

    setIsUnsaved(true);
    setUrl("");
    setPriority("");
    closeModal();
  };

  const handleCopyUrl = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("URL copied to clipboard successfully!");
      })
      .catch((err) => {
        console.error("Failed to copy URL ");
      });
  };

  const handleDeleteUrl = (index) => {
    const confirmation = prompt(
      'Please type "delete" in small case to confirm deletion.'
    );
    if (confirmation === "delete") {
      const updatedSitemap = sitemapUrls.filter((_, i) => i !== index);
      setSitemapUrls(updatedSitemap);
      setIsUnsaved(true);
      alert("URL has been deleted.");
    } else {
      alert("Delete action cancelled. The text entered was incorrect.");
    }
  };

  const convertToXML = (sitemapData) => {
    // XML Header
    let xml = '<?xml version="1.0" encoding="UTF-8"?>\n';
    xml += '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"\n';
    xml += '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n';
    xml +=
      '        xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9">\n';

    // Loop through each sitemap item and convert it to XML
    sitemapData.forEach((urlData) => {
      xml += "  <url>\n";
      xml += `    <loc>${urlData.loc}</loc>\n`;
      xml += `    <lastmod>${urlData.lastmod}</lastmod>\n`;
      xml += `    <priority>${urlData.priority}</priority>\n`;
      xml += "  </url>\n";
    });

    xml += "</urlset>\n";

    return xml;
  };

  const saveToWeb = () => {
    const url = `${HOST}/api/v1/admin/sitemap`;

    fetch(url, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sitemap: convertToXML(sitemapUrls) }),
    })
      .then((response) => {
        if (response.status === 200) {
          alert("Sitemap Saved Success");
          setIsUnsaved(false);
        } else {
          alert("Could not save Sitemap to Website");
        }
      })
      .catch(() => alert("Error in saving Sitemap"));
  };

  useEffect(() => {
    const fetchSitemap = async () => {
      try {
        const response = await fetch(`${HOST}/api/v1/admin/sitemap`);
        const xmlText = await response.text();

        const parser = new XMLParser();
        const jsonObj = parser.parse(xmlText);

        const urls = jsonObj.urlset.url.map((url) => ({
          loc: url.loc,
          lastmod: url.lastmod,
          priority: url.priority,
        }));

        setSitemapUrls(urls);
      } catch (err) {
        setError("Failed to fetch sitemap");
      } finally {
        setLoading(false);
      }
    };

    fetchSitemap();
  }, []);

  // Get current items for pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sitemapUrls.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Next and prev page functions
  const nextPage = () => {
    if (currentPage < Math.ceil(sitemapUrls.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle items per page change
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 flex flex-col items-center justify-center bg-gray-100">
        <div className="flex w-full justify-between gap-8 pt-4">
          <h1 className="text-2xl font-bold mb-4">Sitemap Viewer</h1>

          <div className="flex gap-2">
            <button
              onClick={openModal}
              className="px-4 my-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-300"
            >
              Add
            </button>
            <button
              className={`px-4 my-2 ${
                isUnsaved ? "bg-blue-600" : "bg-gray-400"
              } text-white rounded-md`}
              disabled={!isUnsaved}
              onClick={saveToWeb}
            >
              Save Sitemap to Website
            </button>

            <a
              href="https://www.lucknowlions.com/sitemap.xml"
              rel="noreferrer"
              target="_blank"
              className="flex items-center px-4 my-2 bg-[#e852f5] text-white rounded-md"
            >
              View Sitemap
            </a>
          </div>
        </div>

        {loading && (
          <div className="flex flex-col gap-4 justify-center items-center h-screen">
            <div className="border-t-4 border-blue-500 border-solid w-16 h-16 rounded-full animate-spin"></div>
            Loading
          </div>
        )}

        {error && <div className="mt-4 text-red-600">{error}</div>}

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg w-200">
              <h2 className="text-2xl font-semibold mb-4">Add New URL</h2>
              <form onSubmit={handleAddUrl}>
                <div className="mb-4">
                  <label
                    htmlFor="url"
                    className="block text-sm font-medium text-gray-700"
                  >
                    URL (enter the part after "{domain}")
                  </label>
                  <div className="mt-2 flex-col items-center">
                    <input
                      type="text"
                      id="url"
                      value={url}
                      placeholder="blogs/stocks/top-ev-stocks-in-india"
                      onChange={(e) => setUrl(e.target.value)}
                      className="p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                      required
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="priority"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Priority (0 to 1 in decimals)
                  </label>
                  <input
                    type="number"
                    id="priority"
                    value={priority}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (parseFloat(value) > 1) value = 1;
                      setPriority(value);
                    }}
                    min="0"
                    max="1"
                    step="0.01"
                    className="mt-2 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    required
                  />
                </div>
                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                  >
                    Add URL
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        <div className="bg-white rounded-lg w-full h-full shadow p-6">
          {/* Pagination controls */}
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <span className="mr-2">Items per page:</span>
              <select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                className="border rounded p-1"
              >
                <option value={10}>10</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
              </select>
            </div>

            <div className="flex gap-8">
              {/* Pagination navigation */}
              <div className="flex items-center justify-center">
                <nav className="flex items-center">
                  <button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className={`px-3 py-1 mr-2 rounded ${
                      currentPage === 1
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-blue-600 text-white hover:bg-blue-700"
                    }`}
                  >
                    Previous
                  </button>

                  {/* Generate page number buttons */}
                  {[
                    ...Array(
                      Math.ceil(sitemapUrls.length / itemsPerPage)
                    ).keys(),
                  ].map((number) => (
                    <button
                      key={number + 1}
                      onClick={() => paginate(number + 1)}
                      className={`px-3 py-1 mx-1 rounded ${
                        currentPage === number + 1
                          ? "bg-blue-600 text-white"
                          : "bg-gray-200 hover:bg-gray-300"
                      }`}
                    >
                      {number + 1}
                    </button>
                  ))}

                  <button
                    onClick={nextPage}
                    disabled={
                      currentPage ===
                      Math.ceil(sitemapUrls.length / itemsPerPage)
                    }
                    className={`px-3 py-1 ml-2 rounded ${
                      currentPage ===
                      Math.ceil(sitemapUrls.length / itemsPerPage)
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-blue-600 text-white hover:bg-blue-700"
                    }`}
                  >
                    Next
                  </button>
                </nav>
              </div>
              <div className="flex items-center">
                <span className="mr-2">
                  Showing {indexOfFirstItem + 1}-
                  {Math.min(indexOfLastItem, sitemapUrls.length)} of{" "}
                  {sitemapUrls.length} URLs
                </span>
              </div>
            </div>
          </div>

          <ul className="space-y-2">
            {currentItems.map((urlData, index) => (
              <li
                key={indexOfFirstItem + index}
                className="flex justify-between bg-white ring-1 ring-gray-400 px-6 py-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out"
              >
                <div className="">
                  <a
                    href={urlData.loc}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-medium text-blue-600 hover:text-blue-800 transition-colors duration-200"
                  >
                    {urlData.loc}
                  </a>
                  <div className="">
                    <small className="text-gray-500 block">
                      Last modified: {urlData.lastmod}
                    </small>
                    <small className="text-gray-500 block">
                      Priority: {urlData.priority}
                    </small>
                  </div>
                </div>

                <div className="flex gap-6">
                  <button
                    title="Copy"
                    onClick={() => handleCopyUrl(urlData.loc)}
                  >
                    <Copy className="text-green-600 hover:text-green-800" />
                  </button>

                  <button
                    title="Delete"
                    onClick={() => handleDeleteUrl(indexOfFirstItem + index)}
                  >
                    <Trash className="text-red-600 hover:text-red-800" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
          {/* Pagination navigation */}
          <div className="flex items-center justify-center mt-6">
            <nav className="flex items-center">
              <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className={`px-3 py-1 mr-2 rounded ${
                  currentPage === 1
                    ? "bg-gray-200 cursor-not-allowed"
                    : "bg-blue-600 text-white hover:bg-blue-700"
                }`}
              >
                Previous
              </button>

              {/* Generate page number buttons */}
              {[
                ...Array(Math.ceil(sitemapUrls.length / itemsPerPage)).keys(),
              ].map((number) => (
                <button
                  key={number + 1}
                  onClick={() => paginate(number + 1)}
                  className={`px-3 py-1 mx-1 rounded ${
                    currentPage === number + 1
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300"
                  }`}
                >
                  {number + 1}
                </button>
              ))}

              <button
                onClick={nextPage}
                disabled={
                  currentPage === Math.ceil(sitemapUrls.length / itemsPerPage)
                }
                className={`px-3 py-1 ml-2 rounded ${
                  currentPage === Math.ceil(sitemapUrls.length / itemsPerPage)
                    ? "bg-gray-200 cursor-not-allowed"
                    : "bg-blue-600 text-white hover:bg-blue-700"
                }`}
              >
                Next
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SitemapEdit;
