import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Copy } from "lucide-react";
import { HOST } from "../../../config";
import Sidebar from "../../../Components/Sidebar";
import RichTextEditor from "../../../Components/RichTextEditor/RichTextEditor";
import { nameToSlug } from "../../../Utilities/Methords";
import { fetchCategories } from "../../../api/Helper";

const Blog = () => {
  const { saveType, blog_id } = useParams();

  const [pageTitle, setPageTitle] = useState("");
  const [content, setContent] = useState("");
  const [pageUrl, setPageUrl] = useState("");
  const [seoDetails, setSeoDetails] = useState({
    keywords: "",
    description: "",
  });
  const [ogData, setOgData] = useState({
    title: "",
    description: "",
    image: "",
  });
  const [auther, setAuther] = useState("");
  const [isHidden, setIsHidden] = useState(false);
  const [blogTags, setBlogTags] = useState("");
  const [blogLang, setBlogLang] = useState("en");
  const [createdAt, setCreatedAt] = useState("");
  const [categories, setCategories] = useState([]);

  const [isLoading, setIsLoading] = useState(blog_id ? true : false);
  const [error, setError] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(new Set());

  const fetchContent = async () => {
    try {
      if (!blog_id) {
        setContent("");
        throw new Error("Connot fetch Content");
      }
      const response = await fetch(
        `${HOST}/api/v1/admin/page/${blog_id}?saveType=${saveType}`
      );
      if (!response.ok) throw new Error("Failed to fetch content");
      const data = await response.json();

      if (data.title) setPageTitle(data.title);
      if (data.pageUrl) setPageUrl(data.pageUrl);
      if (data.content) {
        setContent(data.content);
      }
      if (data.seoDetails) setSeoDetails(data.seoDetails);
      if (data.ogData) setOgData(data.ogData);
      if (data.auther) setAuther(data.auther);

      if (data.blogTags) setBlogTags(data.blogTags);
      if (data.blogLang) setBlogLang(data.blogLang);
      if (data.createdAt) setCreatedAt(data.createdAt);
      if (data.isHidden !== undefined) setIsHidden(data.isHidden);
      if (data.selectedCategories) {
        setSelectedCategories(new Set(data.selectedCategories));
      }
    } catch (err) {
      resetAllStates();
      if (blog_id) alert(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const resetAllStates = () => {
    setPageTitle("");
    setContent("");
    setPageUrl("");
    setSeoDetails({
      keywords: "",
      description: "",
    });
    setOgData({
      title: "",
      description: "",
      image: "",
    });
    setAuther("");
    setIsHidden(false);
    setBlogTags("");
    setCreatedAt("");
    setCategories([]);
    setError(null);
    setSelectedCategories(new Set());
  };

  async function renderCategories() {
    const shortedCategories = await fetchCategories();
    setCategories(shortedCategories);
  }

  const handleSave = async (e) => {
    try {
      let url = `${HOST}/api/v1/admin/page/${blog_id}`;
      if (!blog_id) url = `${HOST}/api/v1/admin/blog`;

      const response = await fetch(url, {
        method: blog_id ? "PUT" : "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          title: pageTitle,
          content,
          seoDetails,
          ogData,
          pageUrl,
          auther,
          blogTags,
          blogLang,
          selectedCategories: Array.from(selectedCategories),
          isHidden,
          createdAt,
          saveType: e.target.title,
        }),
      });

      if (response.status === 422) {
        alert(
          "Missing Fields, cannot Save Page \nTitle or Page Url or Content"
        );
        return;
      }
      if (!response.ok) throw new Error("Failed to save content");

      const body = await response.json();

      alert("Page Saved Successfully");
      window.location.href = `/pages/${e.target.title}/${body.id}`;
    } catch (err) {
      setError(err.message);
    }
  };

  const handleCopyUrl = async () => {
    try {
      let urlCategory = "all_blogs";

      for (const category of categories) {
        if (selectedCategories.has(category.id)) {
          urlCategory = category.name;
          break;
        }
      }

      await navigator.clipboard.writeText(
        `https://www.lucknowlions.com/blogs/${nameToSlug(
          urlCategory
        )}/${pageUrl}`
      );
      alert("URL Copied Successfully!");
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  const handleCategoryChange = (id) => {
    setSelectedCategories((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) newSelected.delete(id);
      else newSelected.add(id);

      return newSelected;
    });
  };

  const generateURL = () => {
    const title = pageTitle
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/\s+/g, "-");
    setPageUrl(title);
  };

  useEffect(() => {
    fetchContent();
    renderCategories();
  }, [saveType, blog_id]);

  if (isLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-gray-50">
        <div className="space-y-6 w-96">
          <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
          <div className="h-4 bg-gray-200 rounded w-3/4 animate-pulse"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2 animate-pulse"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-gray-50">
        <div className="bg-red-50 p-6 rounded-lg border border-red-200">
          <p className="text-red-600 font-medium">Error: {error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen bg-gray-200">
      <Sidebar />

      <div className="flex-1 flex flex-wrap gap-8 lg:m-4 m-2">
        <div className="flex-1 flex flex-col gap-4">
          <div className="bg-white rounded-lg shadow-lg p-2">
            <div className="flex flex-col sm:flex-row sm:items-center gap-3">
              <label
                htmlFor="pageTitle"
                className="font-medium text-gray-700 min-w-24"
              >
                Page Title:
              </label>
              <input
                id="pageTitle"
                type="text"
                value={pageTitle}
                onChange={(e) => setPageTitle(e.target.value)}
                className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                maxLength={170}
                placeholder="Enter page title"
              />
            </div>
          </div>

          <div className="flex-1 flex flex-col bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="border-b border-gray-200 bg-white px-6 py-4">
              <div className="flex justify-between items-center">
                <h1 className="text-2xl font-semibold text-gray-900">
                  Edit Content
                </h1>
              </div>
            </div>

            <div className="flex-1 p-4">
              <RichTextEditor
                initialContent={content}
                newContent={setContent}
              />

              <div className="flex gap-20">
                <div>
                  <b>Heading Tags</b>
                  <div className="flex">
                    <div class="ul">
                      <li>H1 (32px)</li>
                      <li>H2 (26px)</li>
                      <li>H3 (22px)</li>
                    </div>
                    <div class="ul pl-4">
                      <li>H4 (18px)</li>
                      <li>H5 (16px)</li>
                      <li>H6 (12px)</li>
                    </div>
                  </div>
                </div>

                <style>
                  {`.bordered-table-all * { @apply border border-slate-400 p-2; }`}
                </style>

                <table className="  border-collapse">
                  <thead>
                    <th className="border border-slate-400 p-2">Lengths</th>
                    <th className="border border-slate-400 p-2">Charactors</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td classname="border border-slate-400 p-2">
                        Meta Title
                      </td>

                      <td classname="border border-slate-400 p-2">60</td>
                    </tr>
                    <tr>
                      <td classname="border border-slate-400 p-2">
                        Meta Description
                      </td>
                      <td classname="border border-slate-400 p-2">160</td>
                    </tr>
                    <tr>
                      <td classname="border border-slate-400 p-2">OG Titlen</td>
                      <td classname="border border-slate-400 p-2">60</td>
                    </tr>
                    <tr>
                      <td classname="border border-slate-400 p-2">
                        OG Description
                      </td>
                      <td classname="border border-slate-400 p-2">65</td>
                    </tr>
                    <tr>
                      <td classname="border border-slate-400 p-2">Url</td>
                      <td classname="border border-slate-400 p-2">75</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="border-b border-gray-200 my-4" />

            <div className="flex">
              <button
                title="draft"
                onClick={handleSave}
                className="flex-1 mx-6 mb-4 sm:w-auto px-4 py-2 bg-green-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
              >
                Save Draft
              </button>
              <button
                title="publish"
                onClick={handleSave}
                className="flex-1 mx-6 mb-4 sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
              >
                Save Publish
              </button>
            </div>
          </div>

          <div className="flex items-center gap-3">
            <label
              htmlFor="pageUrl"
              className="font-medium text-gray-700 min-w-24"
            >
              Page URL:
            </label>

            <div className="flex-1 flex gap-2 items-center">
              <div className="relative flex-1">
                <input
                  id="pageUrl"
                  type="url"
                  value={pageUrl}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\s/g, "");
                    setPageUrl(value);
                  }}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter page URL"
                />
                <button
                  onClick={handleCopyUrl}
                  className="absolute right-2 top-1/2 -translate-y-1/2 p-1 hover:bg-gray-100 rounded-md transition-colors"
                  title="Copy Page URL"
                >
                  <Copy className="w-4 h-4 text-gray-500" />
                </button>
              </div>
              <button
                onClick={generateURL}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
              >
                Generate URL
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col min-w-[20%] gap-2">
          {/* SEO Card */}
          <div className="bg-white rounded-lg shadow-sm">
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold text-gray-900">
                SEO Settings
              </h2>
            </div>
            <div className="p-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Meta Description
                </label>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                  rows="4"
                  value={seoDetails.description}
                  onChange={(e) =>
                    setSeoDetails({
                      ...seoDetails,
                      description: e.target.value,
                    })
                  }
                  maxLength={200}
                  placeholder="Enter meta description..."
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Keywords
                </label>
                <textarea
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                  value={seoDetails.keywords}
                  rows="2"
                  onChange={(e) =>
                    setSeoDetails({
                      ...seoDetails,
                      keywords: e.target.value,
                    })
                  }
                  placeholder="Enter keywords separated by commas..."
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Blog Tags
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                  value={blogTags}
                  onChange={(e) => setBlogTags(e.target.value)}
                  placeholder="Enter Comma Seprated Tags"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Blog Language
                </label>
                <select
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                  value={blogLang}
                  onChange={(e) => setBlogLang(e.target.value)}
                  placeholder="Enter Comma Seprated Tags"
                >
                  <option value="hi">हिन्दी</option>
                  <option value="en">English</option>
                </select>
              </div>
            </div>
          </div>

          {/* Categories */}
          <div className="bg-white rounded-lg shadow-lg">
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold text-gray-900">
                Categories
              </h2>
            </div>
            <div className="px-4 py-4 space-y-2 max-h-[300px] overflow-y-scroll">
              {categories.map((item) => (
                <div key={item.id} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id={`checkbox-${item.id}`}
                    checked={selectedCategories.has(item.id)}
                    onChange={() => handleCategoryChange(item.id)}
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <label
                    htmlFor={`checkbox-${item.id}`}
                    className="text-sm font-medium text-gray-700"
                  >
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* OG Card */}
          <div className="bg-white rounded-lg shadow-sm">
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold text-gray-900">
                OG Settings
              </h2>
            </div>
            <div className="p-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  OG Title
                </label>
                <textarea
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                  value={ogData.title}
                  rows="2"
                  onChange={(e) =>
                    setOgData({
                      ...ogData,
                      title: e.target.value,
                    })
                  }
                  maxLength={170}
                  placeholder="Enter OG title..."
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  OG Description
                </label>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                  rows="4"
                  value={ogData.description}
                  onChange={(e) =>
                    setOgData({
                      ...ogData,
                      description: e.target.value,
                    })
                  }
                  maxLength={200}
                  placeholder="Enter OG description..."
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  OG Image URL
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                  value={ogData.image}
                  onChange={(e) =>
                    setOgData({
                      ...ogData,
                      image: e.target.value,
                    })
                  }
                  placeholder="Enter OG image URL..."
                />
              </div>
            </div>
          </div>

          <div className="bg-white p-4 rounded-lg shadow-sm">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Blog Auther
              </label>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                value={auther}
                onChange={(e) => setAuther(e.target.value)}
                placeholder="Enter Auther Name"
              />
            </div>
            <div className="flex items-center mr-4">
              <input
                type="checkbox"
                id="publishStatus"
                checked={isHidden}
                onChange={(e) => setIsHidden(e.target.checked)}
                className="h-4 w-4 rounded border-gray-300 text-red-600 focus:red-500"
              />
              <label
                htmlFor="publishStatus"
                className="ml-2 text-sm font-medium text-gray-700"
              >
                Hide this Article
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
